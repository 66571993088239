import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import img_elBlurredwallpaperpreview from './images/LandingScreen_elBlurredwallpaperpreview_205816.jpg';
import img_elRoundimage from './images/LandingScreen_elRoundimage_114162.jpg';
import btn_icon_508231 from './images/btn_icon_508231.png';
import btn_icon_587395 from './images/btn_icon_587395.png';

export default class LandingScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elButton = async () => {
    window.open('https://www.facebook.com/profile.php?id=100013111367419', '_blank');
  
  }
  
  
  onClick_elButtonCopy = async () => {
    window.open('https://api.whatsapp.com/send?phone=628996506535', '_blank');
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBlurredwallpaperpreview = {
      backgroundImage: 'url('+img_elBlurredwallpaperpreview+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elRoundimage = {
      height: 'auto',
      borderRadius: '50.0%',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_508231+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '9.157%',
      paddingLeft: '14%',
      backgroundPosition: '3% 50%',
      color: '#FFFFFF',
      textAlign: 'center',
      backgroundColor: '#2f0047',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonCopy = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_587395+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '9.157%',
      paddingLeft: '14%',
      backgroundPosition: '3% 50%',
      color: '#FFFFFF',
      textAlign: 'center',
      backgroundColor: '#2f0047',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen LandingScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBlurredwallpaperpreview" style={style_elBlurredwallpaperpreview} />
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elRoundimage">
            <img style={style_elRoundimage} src={img_elRoundimage} alt=""  />
          </div>
          
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{this.context.locStrings.landingscreen_text_1046197}</div>
            </div>
          </div>
          
          <div className="elButton">
            <button className="baseFont" style={style_elButton} onClick={this.onClick_elButton} >
              {this.context.locStrings.landingscreen_button_508231}
            </button>
          </div>
          
          <div className="elButtonCopy">
            <button className="baseFont" style={style_elButtonCopy} onClick={this.onClick_elButtonCopy} >
              {this.context.locStrings.landingscreen_buttoncopy_587395}
            </button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
